import React from "react";
import "../styles/PrivacyPolicy.css";
import Navbar from "../components/Navbar";
import Footer from "./home/Footer";
import Privacy from "../assets/images/PrivacyPolicy.svg"
const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
    <div className="privacy-policy">
      
      <h1>Privacy Policy</h1>
      
      <section>
        <h2>1. Overview</h2>
        <p>
          Inova8 Inc. operates and maintains multiple public and private websites for general use and customer-specific hosted applications.
          This Privacy Policy applies to all information collected, managed, and controlled by Inova8 Inc.
        </p>
        <p>
          While using Inova8 Inc. websites and services, we may collect both personal and non-personal information about you.
          We are committed to protecting this information and ensuring its security. We utilize this data to communicate with you
          about our services and products, improve our offerings, and operate efficiently. Authorized Inova8 Inc. employees, contractors,
          and third-party service providers may have access to certain data necessary for business operations.
        </p>
        <p>
          Unless explicitly stated otherwise, the information you provide or that is associated with you is never shared with anyone outside
          Inova8 Inc. employees, instructors, authorized contractors, or service providers. We do not trade or sell personal information, except as
          provided in this policy.
        </p>
      </section>

      <section>
        <h2>2. Types of Data Collected</h2>
        <p>
          When you register for an event or course, we collect your name, contact details, affiliation, and event details to manage your registration 
          and inform you of other relevant courses. This information may also be used for course evaluation purposes. When you request more information, 
          we collect the details necessary to address your inquiry and may use them for sales and customer support.
        </p>
        <p>
          Inova8 Inc. utilizes marketing and communications channels, including social media, to promote services. Any interactions through these platforms 
          are subject to this policy. Additionally, we may collect detailed information regarding a user’s processes in hosted services, used strictly per 
          our agreements with customers. Hosted customer data remains confidential and is not shared with third parties without permission.
        </p>
      </section>

      <section>
        <h2>3. Hosted Data and Security</h2>
        <p>
          Customer data hosted on Inova8 Inc. services remains the sole property of the customer. We take all reasonable steps to secure and maintain the 
          privacy of customer data, ensuring that only authorized personnel have access. Data is not shared or combined with that of other customers.
        </p>
      </section>

      <section>
        <h2>4. Vendor, Supplier, and Consultant</h2>
        <p>
          Access to Inova8 Inc. may occasionally work with trusted third-party consultants and vendors to enhance our services. All third parties are 
          subject to strict confidentiality and security policies and operate in compliance with this Privacy Policy.
        </p>
      </section>
      <section>
        <h2>5. Legal Access to Information </h2>
        <p>
        Inovat8 Inc. may share personal information with companies, organizations, or individuals if necessary to comply with legal requirements, enforce our terms, prevent fraud, security, or technical issues, or protect the rights and safety of Inovat8 Inc. and its users.
        </p>
      </section>
      
      <section>
        <h2>6. Website Activity and Cookies </h2>
        <p>
        We automatically collect certain information from website visitors, such as IP addresses, browser types, and usage patterns. This data is used to improve user experience. We use cookies to personalize website interactions and improve functionality. Users can disable cookies through browser settings, though certain site features may be affected.
        </p>
      </section>
      <section>
        <h2>7. Marketing and Advertising </h2>
        <p>
        Inovat8 Inc. may engage third-party advertising services to manage campaigns. These partners use personal data and other information to tailor advertisements. Users may opt out of such campaigns by contacting the relevant advertising providers.
        </p>
      </section>

      <section>
        <h2>8. Data Protection Measures </h2>
        <p>
        We safeguard personal data using encryption, administrative controls, and other security measures. Sensitive web pages use TLS encryption to protect transmitted information.
        </p>
      </section>
      <section>
        <h2>9. Links to External Sites </h2>
        <p>
        Our website may contain links to third-party sites that operate independently. We do not take responsibility for the privacy practices of external websites and recommend reviewing their policies before sharing personal information.
        </p>
      </section>
      <section>
        <h2>10. Compliance with Privacy Shield, HIPAA, and GDPR</h2>
        <p>
        Inovat8 Inc. complies with data protection regulations, including the EU-U.S. Privacy Shield Framework, HIPAA Compliance, and GDPR. If you have concerns regarding our handling of your data, you may contact us at [Insert Contact Email]. Disputes not resolved by us may be referred to relevant data protection authorities.
        </p>
      </section>
      <section>
        <h2>11. Changes to This Privacy Statement </h2>
        <p>
        We reserve the right to modify this privacy statement at any time. If we change our privacy policy, we will post those changes to this statement, the homepage, and other appropriate locations so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
        </p>
        
        <p>
        Contact Us For any questions regarding this Privacy Policy, please contact us at:</p>
        <p><span>Address:</span> 415 McCallum Street, Kingston, Ontario K7K 7L6 Canada<br/>
           <span>Email</span>: hello@innov8.com
        </p>

      </section>
      <img src={Privacy} alt="Privacy SVG" className="privacy-svg" />
    </div>
    <Footer />
    </>

  );
};

export default PrivacyPolicy;
